<template>
    <v-chart
    class="chartz"
    :style="`height:${high}px;`"
    ref="chart"
    :manual-update="true"
    autoresize
    :loading="loading" />
</template>
  
<script>
import { use, util } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { BarChart, LineChart, PieChart } from 'echarts/charts'
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    ToolboxComponent,
    DatasetComponent,
    MarkPointComponent,

} from 'echarts/components'
import { ref, defineComponent, defineAsyncComponent, onMounted, toRefs, watch, reactive } from 'vue'
import VChart from 'vue-echarts'
import { isEmpty } from 'lodash'

use([
    CanvasRenderer,
    BarChart,
    LineChart,
    PieChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    ToolboxComponent,
    DatasetComponent,
    MarkPointComponent,
])

export default defineComponent({
    name: 'EChartDataKokoh',
    components: {
        VChart,
    },
    props: {
        def: {
            default: '',
            type: String,
        },
        title: {
            default: '',
            type: String,
        },
        labely: {
            default: () => [],
            type: Array,
        },
        labelx: {
            default: () => [],
            type: Array,
        },
        series: {
            default: () => [],
            type: Array,
        },
        tooltip: {
            default: () => ({}),
            type: Object,
        },
        dataset: {
            default: () => ({}),
            type: Object,
        },
        legend: {
            default: () => ({}),
            type: Object,
        },
        grid: {
            default: () => ({}),
            type: Object,
        },
        high: {
            default: '300',
            type: String,
        },
    },
    setup: props => {
        const chart = ref()
        const state = reactive({
            axisDefault: [{
                type: "value",
            }],
        })
        const { series, labely, labelx, tooltip, dataset, legend, grid, loading, def } = toRefs(props)

        const option = ref({
            title: {
                text: props.title,
                textStyle: {
                    overflow: 'break',
                    fontSize: '1rem',
                },
            },
            grid: {
                containLabel: true,
                left: '3%',
                right: '3%',
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                },
            },
            legend: {
                type: 'scroll',
                bottom: 0,
                padding: [0, 0, 0, 0],
            },
            xAxis: [],
            yAxis: [],
        })

        const fetchData = async () => {

            if (def.value == 'original') {
                let original = {}

                if (series.value.length > 0) {
                    original.series = series.value
                }

                if (!isEmpty(tooltip.value)) {
                    original.tooltip = tooltip.value
                }

                if (!isEmpty(legend.value)) {
                    original.legend = legend.value
                }

                option.value = original
            } else {
                option.value.xAxis = labelx.value.length > 0 ? labelx.value : state.axisDefault
                option.value.yAxis = labely.value.length > 0 ? labely.value : state.axisDefault
    
                if (series.value.length > 0) {
                    option.value.series = series.value
                }
    
                if (!isEmpty(tooltip.value)) {
                    option.value.tooltip = tooltip.value
                }
    
                if (!isEmpty(legend.value)) {
                    option.value.legend = legend.value
                }
    
                if (!isEmpty(grid.value)) {
                    option.value.grid = grid.value
                }
    
                if (!isEmpty(dataset.value)) {
                    option.value.dataset = dataset.value
                }
            }
            
            await chart.value.setOption(option.value)
        }

        onMounted(async() => {
            await fetchData()
        })

        watch([dataset.value, series], async() => {
            await fetchData()
        })

        return { chart, loading }
    },
})
</script>
